export default{
    dynamic: [
        {
            id:1,
            title: "世泊公司参观听阳机械公司共同交流学习",
            image: require('@/assets/cover/dynamic/1.jpg'),
            date: "2021/11/23",
            info: "参观听阳机械公司"
        },
        {
            id:2,
            title: "世泊公司赴汇川技术公司交流学习",
            image: require('@/assets/cover/dynamic/2.jpg'),
            date: "2021/11/23",
            info: "世泊公司与汇川技术公司开展交流学习活动"
        },
        {
            id:3,
            title: "广州首座世泊智能立体停车库正式开工建设",
            image: require('@/assets/cover/dynamic/3.jpg'),
            date: "2021/11/23",
            info: "世泊智能立体停车库正式开工"
        },
        {
            id:4,
            title: "世泊公司参与第三届深圳国际智慧停车博览会",
            image: require('@/assets/cover/dynamic/4.jpg'),
            date: "2021/11/23",
            info: "世泊参观第三届深圳国际智慧停车"
        },
        {
            id:5,
            title: "世泊公司加入广东省静态交通协会",
            image: require('@/assets/cover/dynamic/5.jpg'),
            date: "2021/11/23",
            info: "世泊公司正式加入广东省静态交通协会"
        },
        {
            id:6,
            title: "世泊公司参与广东省静态交通协会一起携手创造数字停车新时代",
            // title: "世泊公司成为静态交通协会理事单位",
            image: require('@/assets/cover/dynamic/6.jpg'),
            date: "2021/11/23",
            info: "世泊公司与广东省静态交通协会一起创造大数据停车时代"
        },
        {
            id:7,
            title: "世泊公司受邀赴研恒计算机交流学习",
            // title: "世泊公司成为静态交通协会理事单位",
            image: require('@/assets/cover/dynamic/7.jpg'),
            date: "2021/11/23",
            info: "世泊公司参观研恒计算机科技生产线参观学习"
        },
        {
            id:8,
            title: "世泊公司参加大湾区智慧交通产业博览会",
            // title: "世泊公司成为静态交通协会理事单位",
            image: require('@/assets/image/new_static_party/img_index_1.png'),
            date: "2022/09/01",
            info: "世泊公司参加大湾区智慧交通产业博览会"
        },
    ],

    product: [
        {
            id:1,
            title: "世泊智能机器人",
            image: require('@/assets/cover/product/1.jpg'),
            date: "2021/11/23",
            info: "激光导航重载挪车AMR"
        },
    ],

    solution: [
        {
            id:1,
            title: "世泊智能立体停车库",
            image: require('@/assets/cover/solution/1.jpg'),
            date: "2021/11/23",
            info: "全自主设计AMR立体停车库方案《世泊智停》"
        },
    ]
}